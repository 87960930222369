exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apple-health-jsx": () => import("./../../../src/pages/apple-health.jsx" /* webpackChunkName: "component---src-pages-apple-health-jsx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-intro-oxun-rowing-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/aj/Projects/oxun/web/home/blog/intro-oxun-rowing/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-intro-oxun-rowing-index-mdx" */),
  "component---src-pages-help-index-jsx": () => import("./../../../src/pages/help/index.jsx" /* webpackChunkName: "component---src-pages-help-index-jsx" */),
  "component---src-pages-help-mdx-frontmatter-slug-js-content-file-path-help-apple-watch-rowing-mdx": () => import("./../../../src/pages/help/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/aj/Projects/oxun/web/home/help/apple-watch-rowing.mdx" /* webpackChunkName: "component---src-pages-help-mdx-frontmatter-slug-js-content-file-path-help-apple-watch-rowing-mdx" */),
  "component---src-pages-help-mdx-frontmatter-slug-js-content-file-path-help-indoor-rowing-apple-health-mdx": () => import("./../../../src/pages/help/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/aj/Projects/oxun/web/home/help/indoor-rowing-apple-health.mdx" /* webpackChunkName: "component---src-pages-help-mdx-frontmatter-slug-js-content-file-path-help-indoor-rowing-apple-health-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indoor-rowing-index-jsx": () => import("./../../../src/pages/indoor-rowing/index.jsx" /* webpackChunkName: "component---src-pages-indoor-rowing-index-jsx" */),
  "component---src-pages-indoor-rowing-supported-rowing-machines-jsx": () => import("./../../../src/pages/indoor-rowing/supported-rowing-machines.jsx" /* webpackChunkName: "component---src-pages-indoor-rowing-supported-rowing-machines-jsx" */),
  "component---src-pages-logbook-jsx": () => import("./../../../src/pages/logbook.jsx" /* webpackChunkName: "component---src-pages-logbook-jsx" */),
  "component---src-pages-outdoor-rowing-jsx": () => import("./../../../src/pages/outdoor-rowing.jsx" /* webpackChunkName: "component---src-pages-outdoor-rowing-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-pages-trends-index-jsx": () => import("./../../../src/pages/trends/index.jsx" /* webpackChunkName: "component---src-pages-trends-index-jsx" */)
}

